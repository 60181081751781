<template>
  <v-data-table
    v-if="bills"
    :headers="headers"
    :items="bills"
    :items-per-page="100"
    class="elevation-1"
    hide-default-footer
  >
    <template v-slot:item.billingRate="{ item }">
      {{ new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(item.billingRate) }}
    </template>
    <template v-slot:item.billUrl="{ item }">
      <a :href="item.billUrl">Download</a>
    </template>
    <template v-slot:item.auditUrl="{ item }">
      <a :href="item.auditUrl">Download</a>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: 'BillsList',
  props: ['bills', 'headers'],
};
</script>
